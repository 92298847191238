.toggle-button-active {
  > g > g > path {
    stroke: black;
  }
}

.toggle-button-active-dark {
  > g > g > path {
    stroke: #fcfbfd;
  }
}

.toggle-button-inactive {
  > g > g > path {
    stroke: #9b9fb1;
  }
}

.toggle-button-inactive-dark {
  > g > g > path {
    stroke: #816d96;
  }
}
