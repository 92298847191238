.appetize-client-container {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}

.appetize-client-container.light-scrollbar {
  &::-webkit-scrollbar-thumb {
    background-color: #c0c3ce;
  }
}

.appetize-client-container.dark-scrollbar {
  &::-webkit-scrollbar-thumb {
    background-color: #816d96;
  }
}
