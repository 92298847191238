.message-container {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #816d96;
  }
}

.message-container.light-scrollbar {
  &::-webkit-scrollbar-thumb {
    background-color: #c0c3ce;
  }
}

.message-container.dark-scrollbar {
  &::-webkit-scrollbar-thumb {
    background-color: #816d96;
  }
}

.fixed-height-message-container {
  height: calc(100vh - (38px + 4rem));
}
