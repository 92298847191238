.selectbox {
  min-width: 114px;

  .emulator-dropdown__control {
    cursor: pointer !important;
  }

  .emulator-dropdown__menu {
    top: 112% !important;

    .emulator-dropdown__menu-list.light-scrollbar {
      &::-webkit-scrollbar {
        background-color: #f8f9fa;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c0c3ce;
      }
    }

    .emulator-dropdown__menu-list.dark-scrollbar {
      &::-webkit-scrollbar {
        background-color: #1c1525;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #816d96;
      }
    }

    .emulator-dropdown__menu-list {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
      }

      .emulator-dropdown__option {
        cursor: pointer !important;
      }

      .emulator-dropdown__option:last-child {
        border-bottom: none;
      }
    }
  }
}
