@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "roboto-mono";
  src: url("./assets/fonts/RobotoMono-Regular.ttf");
}

@layer base {
  body {
    height: 100vh;
    overflow: hidden;

    #root {
      height: 100%;
    }
  }
}
